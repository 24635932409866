import {customElement} from 'lit/decorators.js'; // eslint-disable-line import/extensions

import {BaseAccordionItemElement} from './base-accordion-item';

export @customElement('accordion-item') class AccordionItemElement extends BaseAccordionItemElement {}

declare global {
  interface HTMLElementTagNameMap {
    'accordion-item': AccordionItemElement;
  }
}
