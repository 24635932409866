import '@material/web/button/elevated-button';
import '@material/web/iconbutton/icon-button';
import '@material/web/icon/icon';

import {LitElement, html, css} from 'lit';
import {customElement, property, query} from 'lit/decorators.js'; // eslint-disable-line import/extensions

import {type MediaSrc} from 'vidstack';
import {type MediaPlayerElement} from 'vidstack/elements';
import vidstackStyles from 'vidstack/player/styles/base.css?url';
import defaultStyles from 'vidstack/player/styles/plyr/theme.css?url';

import {DisableBodyScroll} from './mixins/disableBodyScroll';
import './modal';
import {fetchVideoTask, type FetchVideoTask} from './utils/fileTask';
import {isSmallScreen} from './utils/screenSize';

const resolveVidstackConstructor = async() => await import('./utils/vidstack')
  .then((vidstackModule) => vidstackModule.createVidstackPlayer);

export @customElement('video-content') class VideoContentElement extends DisableBodyScroll(LitElement) {
  @property({type: String, attribute: 'video'})
  public accessor fileUuid = '';

  @query('#modal')
  private accessor modal: HTMLElementTagNameMap['modal-element'] | null = null;

  @query('#video-player')
  private accessor videoPlayer: HTMLVideoElement | null = null;

  private _vidstack: MediaPlayerElement | null = null;

  private _videoTask: FetchVideoTask = fetchVideoTask(this);

  private get _vidstackSources() {
    return this._videoTask.value?.files.map<MediaSrc>((file) => ({
      src: file.url,
      type: 'video/mp4',
      width: file.quality / 16 * 9, // 16:9 aspect ratio
      height: file.quality,
    })) ?? [];
  }

  static styles = css`
    :host {
      display: block;
      margin: 1rem 0;
    }
    .dialog-button {
      --md-sys-color-primary: var(--video-text-color); /* Used for button text */
      --md-elevated-button-container-color: var(--video-button-color);
      --md-elevated-button-container-shape: 3px;
      --md-elevated-button-label-text-size: 18px;
      --md-elevated-button-label-text-weight: 400;
      --md-elevated-button-icon-size: 24px;

      max-width: 100%; /* See https://github.com/material-components/material-web/pull/5756 */
      text-transform: uppercase;
    }
    .loading-indicator {
      --md-circular-progress-active-indicator-color: var(--video-spinner-color);
      --md-circular-progress-size: 30px;
    }
    #video-player {
      max-height: 100%;
    }
    #video-player:not([data-fullscreen]) video {
      max-height: calc(90vh - 2rem); /* This is a hack to ensure proper sizing; see #3753 */
    }
  `;

  render() {
    return html`
      <link rel="stylesheet" href="${defaultStyles}" />
      <link rel="stylesheet" href="${vidstackStyles}" />

      <md-elevated-button
        class="dialog-button"
        ?disabled="${!this._videoTask.isInteractable}"
        has-icon
        @click="${this._openModal}"
      >
        ${this._videoButtonContent()}
      </md-elevated-button>

      <modal-element
        id="modal"
        @open="${this._onOpenModal}"
        @close="${this._onCloseModal}"
      >
        <span slot="title">${this._videoTask.value?.title}</span>
        <video id="video-player" preload="none"></video>
      </modal-element>
    `;
  }

  constructor() {
    super();
    resolveVidstackConstructor(); // eager load Vidstack
  }

  private _videoButtonContent() {
    return this._videoTask.render({
      initial: () => html`<md-icon slot="icon">videocam</md-icon>Openen`,
      pending: () => html`
        <md-icon slot="icon">videocam</md-icon>
        <md-circular-progress class="loading-indicator" indeterminate></md-circular-progress>
      `,
      complete: (video) => html`<md-icon slot="icon">videocam</md-icon>${video.title}`,
      error: () => html`<md-icon slot="icon">videocam_off</md-icon>Video niet gevonden`,
    });
  }

  private async _initializePlayer() {
    const createVidstackPlayer = await resolveVidstackConstructor();

    return createVidstackPlayer(this.videoPlayer!, {src: this._vidstackSources});
  }

  private async _openInFullscreen(player: MediaPlayerElement) {
    player.enterFullscreen();
    player.addEventListener('fullscreen-change', ({detail: isFullscreen}) => {
      if (!isFullscreen) {
        this._closeModal(); // ensure we close the modal when closing fullscreen
      }
    });
  }

  private async _openModal() {
    await this._videoTask.boot();
    this.modal?.open();
  }

  private async _onOpenModal() {
    this._disableBodyScroll();
    this._vidstack?.play(); // If the video already exists, resume playing it.
    this._vidstack ??= await this._initializePlayer(); // Otherwise initialize it.
    if (isSmallScreen()) {
      this._openInFullscreen(this._vidstack); // automatically enter fullscreen on small devices
    }
  }

  private _closeModal() {
    this.modal?.close();
  }

  private _onCloseModal() {
    if (this._vidstack) {
      this._vidstack.pause();
    }
    this._restoreBodyScroll();
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'video-content': VideoContentElement;
  }
}
