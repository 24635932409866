import {type LitElement} from 'lit';
import {state} from 'lit/decorators.js'; // eslint-disable-line import/extensions

import {type Constructor} from '@/js/types/util';

export const DisableBodyScroll = <T extends Constructor<LitElement>>(superClass: T) => {
  class DisableBodyScrollClass extends superClass {
    @state()
    private accessor oldBodyOverflowY = '';

    protected _disableBodyScroll() {
      this.oldBodyOverflowY = document.body.style.overflowY;
      document.body.style.overflowY = 'hidden';
    }

    protected _restoreBodyScroll() {
      document.body.style.overflowY = this.oldBodyOverflowY;
    }
  }

  return DisableBodyScrollClass;
};
