import {LitElement, html, css, nothing} from 'lit';
import {customElement, property} from 'lit/decorators.js'; // eslint-disable-line import/extensions
import {ifDefined} from 'lit/directives/if-defined.js'; // eslint-disable-line import/extensions

import {fetchFileTask, type FetchFileTask} from './utils/fileTask';

export @customElement('image-content') class ImageContentElement extends LitElement {
  @property({type: String, attribute: 'image'})
  public accessor fileUuid = '';

  private _imageTask: FetchFileTask = fetchFileTask(this);

  static styles = css`
    img {
      max-width: 100%;
      height: auto;
      border: none;
    }
  `;

  render() {
    return this._imageTask.render({
      pending: () => nothing,
      complete: (file) => html`
        <a target="_blank" href="${file.url}">
          <img
            data-file-path="${ifDefined(file.path)}"
            src="${file.url}"
            loading="lazy"
            alt="${file.title}"
            tabindex="-1"
          />
        </a>`,
      error: () => html`<img src="data:image/jpg," alt="Afbeelding niet gevonden" />`, // force a broken image to appear
    });
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'image-content': ImageContentElement;
  }
}
