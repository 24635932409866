import {LitElement, html, css} from 'lit';
import {customElement, property} from 'lit/decorators.js'; // eslint-disable-line import/extensions

export @customElement('animatable-page') class AnimatablePageElement extends LitElement {
  @property({type: String})
  public accessor title = '';

  @property({type: Boolean})
  public accessor disabled = false;

  static styles = css`
    :host {
      display: block;
      padding: 1rem 0;
      height: auto;
    }
  `;

  render() {
    return html`<slot></slot>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'animatable-page': AnimatablePageElement;
  }
}
